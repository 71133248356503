
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { EmpreendimentoService } from "@/core/services/cadastros";
import { EmpresaService } from "@/core/services/compras/EmpresaService";
import { GenerateYearOptions } from "@/assets/scripts/helper";
 
@Component
export default class CfgEmpreendimento extends Vue {
    filter: {
      empreendimentoId:number;
      empresaId:number;
      ano:string;
    } = {
      empreendimentoId:0,
      empresaId:0,
      ano:''
    };

    valid = true; 
    fieldRules: any[] = [(v: any) => !!v || "Campo obrigatório"];

    empreendimentos = [];
    empresas = [];
    anos = GenerateYearOptions();
      
   breadCrumbLinks: any[] = [
    { text: 'Home', href: '/financeiro/painel/kpi',},
    { text: 'Relatório DIMOB', disabled: true, href: '/financeiro/painel/despesas'}
  ]
 
     
    mounted() {
      new EmpreendimentoService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
          this.empreendimentos = res.data.items
        }, 
      );

      new EmpresaService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(
        (res) => {
          this.empresas = res.data.items
        }, 
      );
  }   

  Visualizar() {    
    let routeLink = this.$router.resolve({name: 'relDimob',
      query:{
        empresaId: this.filter.empresaId ? this.filter.empresaId.toString() : '',
        empreendimentoId: this.filter.empreendimentoId ? this.filter.empreendimentoId.toString() : '',
        ano: this.filter.ano
      }
    });

    window.open(routeLink.href, '_blank');
  }
}
